// eslint-disable-next-line filename-rules/match
import { NextSeo } from 'next-seo'
import * as UI from '@/ui'

const Custom404 = () => {
  return (
    <>
      <NextSeo title="404 Page Not Found" description="Fallback for a broken link." />

      <UI.Block gap="xxl" className="relative max-w-5xl mx-auto z-10 mt-0 md:mt-40">
        <UI.Heading size="medium" color="lightBlue" className="text-center md:text-7xl">
          Page Not Found
        </UI.Heading>

        <UI.Paragraph size="large" color="lightBlue" className="text-center -mt-5 md:text-2xl">
          {`We can't seem to find the page you were looking for.`}
        </UI.Paragraph>
      </UI.Block>
    </>
  )
}

export default Custom404
